var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible:
          _vm.$store.state.multimedia_meetingmode.notSupportSharingScreenDialog,
        "custom-class": "notSupportSharingScreenDialog",
        "append-to-body": true,
        "destroy-on-close": true,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          return _vm.$set(
            _vm.$store.state.multimedia_meetingmode,
            "notSupportSharingScreenDialog",
            $event
          )
        },
        close: _vm.dialogClose
      }
    },
    [
      _c("div", { staticClass: "tipsBox" }, [
        _c("i", { staticClass: "iconfont_Me icon-remind-fill iconStyle" }),
        _vm._v("\n    " + _vm._s(_vm.text) + "\n  ")
      ]),
      _c("div", { staticClass: "tipsFooter" }, [
        _c(
          "div",
          {
            staticClass: "tipsButton commomButtonHover",
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.dialogClose.apply(null, arguments)
              }
            }
          },
          [_c("i", { staticClass: "iconfont_Me icon-check iconStyle" })]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }