<template>
  <el-dialog
    :visible.sync="
      $store.state.multimedia_meetingmode.notSupportSharingScreenDialog
    "
    custom-class="notSupportSharingScreenDialog"
    @close="dialogClose"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <div class="tipsBox">
      <i class="iconfont_Me icon-remind-fill iconStyle"></i>
      {{ text }}
    </div>
    <div class="tipsFooter">
      <div class="tipsButton commomButtonHover" @click.stop="dialogClose">
        <i class="iconfont_Me icon-check iconStyle"></i>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      text: "",
    };
  },
  mounted() {
    // 判断是window系统还是mac系统
    let isMac = /macintosh|mac os x/i.test(navigator.userAgent);
    if (isMac) {
      this.text = this.$t("browserlimit_mac");
    } else {
      this.text = this.$t("browserlimit");
    }
  },
  methods: {
    dialogClose() {
      this.$store.commit("setNotSupportSharingScreenDialog", false);
    },
  },
};
</script>
<style lang="stylus">
.notSupportSharingScreenDialog
  width: 450px !important;
  border-radius: 5px !important;
  margin-top 0 !important;
  .el-dialog__header
    display none;
  .el-dialog__body
    padding 40px 20px 15px 20px;
    .tipsBox
      width 100%;
      height 20px;
      display flex;
      align-items center;
      justify-content flex-start;
      .iconStyle
        font-size 24px;
        color #ffb320;
        margin-right 3px;
    .tipsFooter
      width 100%;
      height 34px;
      margin-top 20px;
      display flex;
      align-items center;
      justify-content flex-end;
      .tipsButton
        width 46px;
        height 100%;
        cursor pointer;
        border-radius 5px;
        display flex;
        align-items center;
        justify-content center;
        .iconStyle
          color #FFFFFF;
          font-size 16px;
</style>
